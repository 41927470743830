<template>
    <div>
        <div class="header_holder text-center ma">
            <!--<router-link to="/message_add" class="left_btn">
                <v-icon small right>fas fa-paper-plane</v-icon>
            </router-link>-->
            <div class="rounder_top  mb-2">
                <div class="card_header ubuntu_b primer">
                    Komunikacija
                </div>
            </div>
        </div>
        <div v-if="messages.length">
            <MessageCard
                v-for="message in messages" :key="message.id" v-bind:message="message"
            ></MessageCard>
        </div>
        <div v-if="!messages.length" class="pa-6 text-center rounder primer mt-12">
            <!--<router-link to="/message_add" color="black">
                <v-icon small right>fas fa-paper-plane</v-icon> trenutno nemate poruke, želite li nam poslati jednu
            </router-link>-->
            trenutno nemate poruke
        </div>
    </div>
</template>
<script>
import MessageCard from './MessageCard.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
        }
    },
    components: { MessageCard },
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            messages: 'messages/getMessages',
            message_types: 'messages/getMessageTypes',
            userCredentials: 'user/getCredentials'
        })
    },
    methods: {
        ...mapActions('messages',[
            'changeMessageStatus'
        ]),
    },
    created () {
        this.$store.dispatch('messages/getAllMessages')
        this.$store.dispatch('messages/getAllMessageTypes')
    },
    beforeCreate(){
        if(this.$store.getters['user/getCredentials'].reklamacije == 0) this.$router.push({ name: 'not_auth' })
    }
}
</script>
<style scoped>
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
</style>