<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <MessagesComponent />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MessagesComponent from '../components/MessagesComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { MessagesComponent },
}
</script>
